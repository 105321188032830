.noticias{
  padding-top: 100px;

  .titulo{
    text-align: center;
    margin: 0 auto 30px auto;
    font-family: MontserratBold;
    font-size: 40px;
    line-height: 44px;
    color: #002069;

    @include media-breakpoint-down(sm){
      font-size: 30px;
      line-height: 34px;
    }
  }

  .container{
    @include media-breakpoint-up(lg){
      padding: 0 100px;
    }
  }

  .box__conteudo{
    margin-bottom: 100px;
  }

  .card__noticia{
    padding: 30px 0;
    border-bottom: 2px solid #e4e4e4;

    .card__link{
      display: flex;
      align-items: start;
      justify-content: start;
      text-decoration: none;

      @include media-breakpoint-down(md){
        flex-direction: column;
      }

      .card__img{
        min-width: 260px;
        text-align: center;
        margin: 0 auto;

        @include media-breakpoint-down(md){
          margin-bottom: 20px;
        }
      }

      .card__texto{
        @include media-breakpoint-up(lg){
          margin-left: 30px;
        }

        .card__titulo{
          font-family: MontserratBold;
          font-size: 20px;
          line-height: 24px;
          color: #002069;
        }

        .card__desc{
          font-family: MontserratRegular;
          font-size: 16px;
          line-height: 18px;
          color: #666666;
          margin-top: 15px;
        }

        .card__detalhes{
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
          justify-content: start;

          font-family: MontserratBold;
          font-size: 16px;
          line-height: 18px;
          color: #002069;
          margin-top: 30px;

          i{
            margin-left: 10px;
          }
        }
      }

      &:hover{
        .card__detalhes{
          text-decoration: underline;

          i{
            animation: headShake 3s linear infinite;
          }
        }
      }
    }
  }

  .text__center{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;

    .btn__style{
      &:hover{
        color: #FFF;
        animation: pulse 3s linear;
      }
    }
  }
}