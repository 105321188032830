.secao__banner{
  position: relative;

  .box__texto{
    position: absolute;
    left: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    margin: 0 auto;
    color: var(--cor);

    @media (max-width: 1700px){
      top: 50%;
    }

    @media (min-width: 1700px){
      top: calc(50% + 100px);
    }

    @include media-breakpoint-only(lg){
      width: 800px;
    }

    @include media-breakpoint-only(md){
      width: 700px;
    }

    @include media-breakpoint-only(sm){
      width: 400px;
    }

    @include media-breakpoint-only(xs){
      width: 300px;
    }

    .titulo{
      font-family: MontserratRegular;
      font-size: 55px;
      line-height: 59px;
      margin-bottom: 30px;

      @include media-breakpoint-only(lg){
        font-size: 45px;
        line-height: 49px;
        margin-bottom: 30px;
      }

      @include media-breakpoint-only(md){
        font-size: 35px;
        line-height: 39px;
        margin-bottom: 25px;
      }

      @include media-breakpoint-only(sm){
        font-size: 25px;
        line-height: 29px;
        margin-bottom: 20px;
      }

      @include media-breakpoint-only(xs){
        font-size: 13px;
        line-height: 17px;
        margin-bottom: 10px;
      }

      span{
        font-family: MontserratBold;
      }
    }

    .subtitulo{
      border: 3px solid var(--cor);
      font-family: MontserratBold;
      font-size: 30px;
      line-height: 34px;
      padding: 20px;

      @include media-breakpoint-only(lg){
        font-size: 25px;
        line-height: 29px;
        padding: 20px;
      }

      @include media-breakpoint-only(md){
        font-size: 20px;
        line-height: 24px;
        padding: 15px;
      }

      @include media-breakpoint-down(sm){
        font-size: 12px;
        line-height: 16px;
        padding: 10px;
      }
    }
  }

  #banner-carrosel{
    .carousel-indicators{
      display: flex;
      align-items: center;
      justify-content: center;
      margin: 0;
      bottom: 40px;

      @include media-breakpoint-only(md){
        bottom: 30px;
      }

      @include media-breakpoint-only(sm){
        bottom: 20px;
      }

      @include media-breakpoint-only(xs){
        bottom: 8px;
      }

      li{
        width: 14px;
        min-width: 14px;
        height: 14px;
        min-height: 14px;
        border-radius: 50%;
        border: 1px solid #FFF;
        background-color: transparent;
        opacity: 1;
        margin: 0 3px;

        &.active{
          opacity: 1;
          background-color: #FFF;
        }
      }
    }
  }
}

.secao__sobre{
  padding: 100px 0;

  .titulo{
    font-family: MontserratBold;
    font-size: 40px;
    line-height: 44px;
    color: #002069;
    margin-bottom: 20px;

    @include media-breakpoint-down(sm){
      font-size: 30px;
      line-height: 34px;
    }
  }

  .texto__style{
    text-align: justify;
  }

  .btn__style{
    &:hover{
      color: #FFF;
      animation: tada 3s linear;
    }
  }

  .row{
    align-items: center;

    .box__img{
      text-align: center;
      margin: 0 auto;

      @include media-breakpoint-down(md){
        margin-top: 50px;
      }
    }
  }
}

.secao__servicos{
  padding: 50px 0 100px 0;

  .header__titulo{
    text-align: center;
    margin: 0 auto 50px auto;

    @include media-breakpoint-up(lg){
      max-width: 820px;
    }

    .titulo{
      font-family: MontserratBold;
      font-size: 40px;
      line-height: 44px;
      color: #002069;
      margin-bottom: 20px;

      @include media-breakpoint-down(sm){
        font-size: 30px;
        line-height: 34px;
      }
    }

    .desc{
      font-family: MontserratRegular;
      font-size: 16px;
      color: #666666;
      line-height: calc(1em + 4px);
    }
  }

  .box__cards{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 30px;
    row-gap: 30px;

    @include media-breakpoint-down(md) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media-breakpoint-down(sm) {
      grid-template-columns: repeat(1, 1fr);
    }

    .card__servico{
      text-align: center;
      margin: 0 auto;

      img{
        border-radius: 5px;
      }

      .card__link{
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        border: 2px solid #dbdbdb;
        border-radius: 5px;
        padding: 30px;
        min-width: 350px;
        max-height: 350px;
        min-height: 350px;
        text-decoration: none;

        .card__ordem{
          font-family: MontserratExtraBold;
          font-size: 60px;
          line-height: 60px;
          color: #dbdbdb;
        }

        .card__nome{
          font-family: MontserratBold;
          font-size: 20px;
          line-height: 24px;
          color: #002069;
          margin-top: 20px;
          text-align: start;
        }

        .card__desc{
          font-family: MontserratRegular;
          font-size: 16px;
          line-height: 18px;
          color: #666666;
          margin-top: 20px;
          text-align: justify;
          height: calc(18px * 6);
          overflow-y: auto;

          &::-webkit-scrollbar{
            width: 8px;
          }
          &::-webkit-scrollbar-thumb{
            background-color: #666;
            border-radius: 4px;
            border: 1px solid #fff;
          }
        }

        .card__mais{
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
          justify-content: start;
          margin-top: 30px;
          font-family: MontserratBold;
          font-size: 16px;
          line-height: 16px;
          color: #002069;

          i{
            margin-left: 10px;
          }
        }

        &:hover{
          border: 2px solid #002069;

          .card__mais{
            i{
              animation: headShake 3s linear infinite;
            }
          }
        }
      }
    }
  }
}