.quem-somos{
  padding-top: 100px;

  .titulo{
    font-family: MontserratBold;
    font-size: 40px;
    line-height: 44px;
    color: #002069;
    margin-bottom: 20px;

    @include media-breakpoint-down(sm){
      font-size: 30px;
      line-height: 34px;
    }
  }

  .box__conteudo {
    margin-bottom: 50px;

    @include media-breakpoint-down(md) {
      display: flex;
      flex-direction: column;
      align-items: center;
    }

    .box__img {
      float: right;
      clear: both;
      margin-left: 30px;
      margin-bottom: 20px;

      @include media-breakpoint-down(md) {
        margin-right: 0;
        margin-bottom: 30px;
        display: flex;
        justify-content: center;
      }
    }
  }

  .secao__equipe{
    margin-bottom: 100px;

    .titulo{
      text-align: center;
      margin: 0 auto 20px auto;
    }

    .box__img{
      text-align: center;
      margin: 0 auto;
    }
  }
}