@font-face {
    font-family: 'LatoRegular';
    src: url('../fonts/LatoRegular/Lato-Regular.woff2') format('woff2'),
        url('../fonts/LatoRegular/Lato-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'MontserratBold';
    src: url('../fonts/MontserratBold/Montserrat-Bold.woff2') format('woff2'),
        url('../fonts/MontserratBold/Montserrat-Bold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'MontserratExtraBold';
    src: url('../fonts/MontserratExtraBold/Montserrat-ExtraBold.woff2') format('woff2'),
        url('../fonts/MontserratExtraBold/Montserrat-ExtraBold.woff') format('woff');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'MontserratItalic';
    src: url('../fonts/MontserratItalic/Montserrat-Italic.woff2') format('woff2'),
        url('../fonts/MontserratItalic/Montserrat-Italic.woff') format('woff');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}


@font-face {
    font-family: 'MontserratRegular';
    src: url('../fonts/MontserratRegular/Montserrat-Regular.woff2') format('woff2'),
        url('../fonts/MontserratRegular/Montserrat-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}


@font-face {
    font-family: 'MontserratSemiBold';
    src: url('../fonts/MontserratSemiBold/Montserrat-SemiBold.woff2') format('woff2'),
        url('../fonts/MontserratSemiBold/Montserrat-SemiBold.woff') format('woff');
    font-weight: 600;
    font-style: normal;
    font-display: swap;
}

