// bg-position(x,y) width height
$icons: (
	'avatar': -74px -31px 17px 20px,
	'chat': -42px 0px 22px 22px,
	'html': 0px 0px 32px 32px,
	'instagram': -101px 0px 15px 15px,
	'left': -29px -42px 20px 12px,
	'right': 0px -71px 20px 12px,
	'seta': -30px -71px 20px 12px,
	'suporte': -74px 0px 17px 21px,
	'whatsapp': 0px -42px 19px 19px,
);

@if $nativeCssIconVarialables {
	:root{
		@each $icon,$value in $icons {
			/*icon-#{$icon}*/
			--icon-#{$icon}-width: #{nth($value, 3)};
			--icon-#{$icon}-height: #{nth($value, 4)};
			--icon-#{$icon}-bg: #{nth($value, 1)} #{nth($value, 2)};
		}
	}
}

.icon{
	background-image: url('../images/sprites.png');
	background-repeat:no-repeat;
	background-size: 116px 83px;

	display: inline-block;
	vertical-align: middle;

}

.icon__mask{
	mask-image: url('../images/sprites.png');
	mask-repeat:no-repeat;
	mask-size: 116px 83px;

	display: inline-block;
	vertical-align: middle;
	background-color: currentColor;
}

@each $icon,$value in $icons {
	.#{$icon} {
		width: nth($value,3);
		height: nth($value,4);
		$sprite-position: #{nth($value,1)} #{nth($value,2)};

		&.icon{
			background-position: $sprite-position;
		}

		&.icon__mask{
			mask-position: $sprite-position;
		}
	}
}
