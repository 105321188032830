.segura__btns{
  position: fixed;
  z-index: 999;
  box-shadow: 0 0 15px 0 rgba(0,0,0,.3);
  background-color: #FFF;
  border-radius: 5px;
  padding: 8px;
  right: 15px;
  bottom: 15px;

  display: flex;
  align-items: center;
  justify-content: center;

  &.rodape{
    bottom: 100px;
  }

  .btn__flutuante{
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 5px;
    font-family: MontserratBold;
    font-size: 13px;
    line-height: 13px;
    color: #002069;
    padding: 13px 20px;
    text-align: center;

    @include media-breakpoint-down(xs){
      flex-direction: column;
    }

    i{
      @include media-breakpoint-up(sm){
        margin-right: 5px;
      }

      @include media-breakpoint-down(xs){
        margin-bottom: 5px;
      }
    }

    &:hover{
      text-decoration: none;
      background: rgb(0,32,105);
      background: linear-gradient(180deg, rgba(0,32,105,1) 0%, rgba(46,84,169,1) 50%, rgba(0,32,105,1) 100%);
      color: #FFF;
    }
  }
}