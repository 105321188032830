.servicos{
  padding-top: 100px;

  .header__titulo{
    text-align: center;
    margin: 0 auto 50px auto;

    @include media-breakpoint-up(lg){
      max-width: 820px;
    }

    .titulo{
      font-family: MontserratBold;
      font-size: 40px;
      line-height: 44px;
      color: #002069;
      margin-bottom: 20px;

      @include media-breakpoint-down(sm){
        font-size: 30px;
        line-height: 34px;
      }
    }

    .desc{
      font-family: MontserratRegular;
      font-size: 16px;
      color: #666666;
      line-height: calc(1em + 4px);
    }
  }

  .box__cards{
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    column-gap: 30px;
    row-gap: 30px;
    margin-bottom: 100px;

    @include media-breakpoint-down(md) {
      grid-template-columns: repeat(2, 1fr);
    }

    @include media-breakpoint-down(sm) {
      grid-template-columns: repeat(1, 1fr);
    }

    .card__servico{
      text-align: center;
      margin: 0 auto;

      img{
        border-radius: 5px;
      }

      .card__link{
        display: flex;
        flex-direction: column;
        align-items: start;
        justify-content: center;
        border: 2px solid #dbdbdb;
        border-radius: 5px;
        padding: 30px;
        min-width: 350px;
        max-height: 350px;
        min-height: 350px;
        text-decoration: none;

        .card__ordem{
          font-family: MontserratExtraBold;
          font-size: 60px;
          line-height: 60px;
          color: #dbdbdb;
        }

        .card__nome{
          font-family: MontserratBold;
          font-size: 20px;
          line-height: 24px;
          color: #002069;
          margin-top: 20px;
          text-align: start;
        }

        .card__desc{
          font-family: MontserratRegular;
          font-size: 16px;
          line-height: 18px;
          color: #666666;
          margin-top: 20px;
          text-align: justify;
          height: calc(18px * 6);
          overflow-y: auto;

          &::-webkit-scrollbar{
            width: 8px;
          }
          &::-webkit-scrollbar-thumb{
            background-color: #666;
            border-radius: 4px;
            border: 1px solid #fff;
          }
        }

        .card__mais{
          display: flex;
          flex-direction: row-reverse;
          align-items: center;
          justify-content: start;
          margin-top: 30px;
          font-family: MontserratBold;
          font-size: 16px;
          line-height: 16px;
          color: #002069;

          i{
            margin-left: 10px;
          }
        }

        &:hover{
          border: 2px solid #002069;

          .card__mais{
            i{
              animation: headShake 3s linear infinite;
            }
          }
        }
      }
    }
  }
}