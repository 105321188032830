.noticia-detalhes{
  padding-top: 100px;

  .titulo{
    text-align: start;
    margin: 0 auto 30px auto;
    font-family: MontserratBold;
    font-size: 40px;
    line-height: 44px;
    color: #002069;

    @include media-breakpoint-down(sm){
      font-size: 30px;
      line-height: 34px;
    }
  }

  .box__img{
    text-align: center;
    margin: 0 auto 40px auto;
  }

  .box__conteudo{
    margin-bottom: 100px;

    .texto__style{
      text-align: justify;
    }
  }

  .text__center{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 40px;

    .btn__style{
      &:hover{
        color: #FFF;
        animation: headShake 3s linear;
      }
    }
  }
}