/*
	DEFINIR:
		min-width: 195px;
		color: #FFF;
		padding: 13px;

		background: rgb(0,32,105);
		background: linear-gradient(180deg, rgba(0,32,105,1) 0%, rgba(46,84,169,1) 50%, rgba(0,32,105,1) 100%);

		&:hover{
			transition: all .3s linear;
			text-decoration: none;
			animation: tada 3s linear;

			background-color: #7c7c7c;
			color: #FFF;
		}
 */

.btn__style{
	display: inline-flex;
	align-items: center;
	justify-content: center;

	height: auto;
	border-radius: 5px;
	border: none;

	font-family: MontserratBold;
	font-size: 13px;
	line-height: 13px;

	text-align: center;
	margin: 0 auto;

	min-width: 180px;
	color: #FFF;
	padding: 16px;
	background: rgb(0,32,105);
	background: linear-gradient(180deg, rgba(0,32,105,1) 0%, rgba(46,84,169,1) 50%, rgba(0,32,105,1) 100%);

	&:hover{
		transition: all .3s linear;
		text-decoration: none;
	}
}