.servico-detalhes{
  padding-top: 100px;

  .container{
    padding-bottom: 100px;
  }

  .titulo{
    font-family: MontserratBold;
    font-size: 40px;
    line-height: 44px;
    color: #002069;
    margin-bottom: 20px;

    @include media-breakpoint-down(sm){
      font-size: 30px;
      line-height: 34px;
    }
  }

  #servico-carrosel{
    position: relative;

    @include media-breakpoint-down(md){
      margin-top: 30px;
    }

    .carousel-controls_custom{
      position: absolute;
      bottom: 30px;
      right: 30px;

      .carousel-control-prev_custom,
      .carousel-control-next_custom{
        color: #002069;
        text-decoration: none;
      }

      .carousel-control-prev_custom{
        margin-right: 20px;
      }
    }
  }

  .text__center{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;

    .btn__style{
      &:hover{
        color: #FFF;
        animation: headShake 3s linear;
      }
    }
  }
}