footer{
  background-color: #002069;
  padding: 30px 0;

  .container{
    display: flex;
    align-items: center;
    justify-content: space-between;

    @include media-breakpoint-down(md){
      flex-direction: column;
      justify-content: center;
    }

    .box__creditos{
      color: #FFF;
      font-family: LatoRegular;
      font-size: 12px;
      line-height: 14px;

      @include media-breakpoint-down(md){
        margin-bottom: 10px;
        text-align: center;
      }
    }

    .box__redes{
      display: flex;
      align-items: center;
      justify-content: center;

      @include media-breakpoint-down(xs){
        flex-direction: column;
      }

      .title__redes{
        color: #FFF;
        font-family: MontserratBold;
        font-size: 13px;
        line-height: 15px;
        margin-right: 10px;

        @include media-breakpoint-down(xs){
          margin-bottom: 7px;
          margin-right: 0;
        }
      }

      .style__redes{
        display: flex;
        align-items: center;
        justify-content: center;

        .icone__redes{
          display: flex;
          align-items: center;
          justify-content: center;
          border-radius: 50%;
          border: 2px solid #FFF;
          background-color: transparent;
          color: #FFF;
          width: 34px;
          min-width: 34px;
          height: 34px;
          margin-right: 3px;

          &:last-child{
            margin-right: 0;
          }

          &:hover{
            transition: all .3s linear;
            animation: pulse 3s linear;
            text-decoration: none;
            background-color: #FFF;
            color: #002069;
            border-color: #FFF;
          }
        }
      }
    }
  }
}