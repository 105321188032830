.politicas{
  padding: 100px 0;

  .titulo{
    text-align: center;
    margin: 0 auto 30px auto;
    font-family: MontserratBold;
    font-size: 40px;
    line-height: 44px;
    color: #002069;

    @include media-breakpoint-down(sm){
      font-size: 30px;
      line-height: 34px;
    }
  }

  .text__center{
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;

    .btn__style{
      &:hover{
        color: #FFF;
        animation: headShake 3s linear;
      }
    }
  }
}