.main__menu{
  list-style: none;
  padding-left: 0;
  margin-bottom: 0;
}

@include media-breakpoint-up(lg){
  .topo{
    box-shadow: 0 0 25px 0 rgba(0,0,0,.2);
    -webkit-box-shadow: 0 0 25px 0 rgba(0,0,0,.2);
    -moz-box-shadow: 0 0 25px 0 rgba(0,0,0,.2);

    @media (max-width: 1700px){
      position: sticky;
      top: 0;
      z-index: 2000;
      background-color: #FFF;
    }

    @media (min-width: 1700px){
      position: absolute;
      width: 100%;
      left: 0;
      z-index: 1000;
      background-color: rgba(#FFF,.6);

      &.fixed{
        position: sticky;
        top: 0;
        z-index: 2000;
        background-color: #FFF;
        transition: all .3s linear;
      }
    }

    &.topo__interna{
      position: sticky;
      top: 0;
      z-index: 2000;
      background-color: #FFF;
    }

    .container{
      display: flex;
      justify-content: space-between;
      padding: 0;

      .style__topo__logo{
        min-width: 150px;
      }

      .main__menu{
        display: flex;
        justify-content: end;

        .form__area{
          position: absolute;
          top: 70%!important;
          left: 50%;
          transform: translateX(-50%);
          width: 300px;
          z-index: 300;
          padding: 20px 40px;
          border-radius: 5px;
          background-color: #FFF;
          box-shadow: 0 0 15px 0 rgba(0,0,0,.3);
          -webkit-box-shadow: 0 0 15px 0 rgba(0,0,0,.3);
          -moz-box-shadow: 0 0 15px 0 rgba(0,0,0,.3);

          &:before{
            content: '';
            position: absolute;
            top: -10px;
            left: 50%;
            transform: translateX(-50%);
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid #FFF;
          }

          .style__form__area{
            text-align: center;
            margin: 0 auto;

            .form-group {
              margin-bottom: 10px;

              label{
                font-family: MontserratBold;
                font-size: 16px;
                line-height: 16px;
                color: #000000;
              }

              .campo__input {
                display: block;
                width: 100%;
                padding: 10px;
                background-color: #FFF;
                color: #666666;
                resize: none;
                font-family: MontserratRegular;
                font-size: 15px;
                border: 1px solid #6666;
                border-radius: 5px;
                text-align: center;

                &::placeholder {
                  color: #666666;
                  font-size: 13px;
                  font-family: MontserratItalic;
                }

                &:focus {
                  outline: none;
                }
              }
            }

            .form__link{
              font-family: MontserratItalic;
              font-size: 12px;
              line-height: 12px;
              color: #666666;
              margin-top: 10px;
            }

            .form__btn {
              margin-top: 20px;

              .btn__style {
                &:hover{
                  color: #FFF;
                  animation: tada 3s linear;
                }
              }
            }
          }
        }

        .menu__item{
          margin: 0 20px;
          position: relative;
          display: flex;

          button{
            border: none;
            background-color: transparent;
          }

          .menu__link{
            position: relative;
            display: flex;
            align-items: center;
            font-family: MontserratSemiBold;
            font-size: 14px;
            color: #002069;
            padding: 0;
            text-align: center;
            text-decoration: none;
            transition: all .3s linear;

            &:before{
              content: '';
              position: absolute;
              width: 2px;
              height: 35%;
              background-color: #002069;
              top: 0;
              left: 50%;
              transform: translateX(-50%);
              opacity: 0;
            }

            &:after{
              content: '';
              position: absolute;
              width: 2px;
              height: 35%;
              background-color: #002069;
              bottom: 0;
              left: 50%;
              transform: translateX(-50%);
              opacity: 0;
            }

            &:hover{

            }

            i{
              margin-right: 5px;
            }
          }

          &.menu__item--active{
            .menu__link{
              &:before,
              &:after{
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }

  .topo__mobile{
    display: none;
  }
}

@include media-breakpoint-down(md){
  .topo{
    position: sticky;
    top: 0;
    z-index: 2000;
    background-color: #FFF;

    .topo__mobile{
      box-shadow: 0 5px 30px -10px rgba(0,0,0,.3);
      -webkit-box-shadow: 0 5px 30px -10px rgba(0,0,0,.3);
      -moz-box-shadow: 0 5px 30px -10px rgba(0,0,0,.3);
      padding: 10px 0;
      background-color: #FFF;

      .container{
        display: flex;
        align-items: center;
        justify-content: space-between;

        .style__topo__logo{
          margin-right: 10px;
          margin-left: 10px;
          max-width: 90px;
        }

        .mbl__toggler{
          border: none;
          background-color: transparent;
          font-size: 40px;
          color: #002069;
        }
      }
    }

    .topo__main{
      width: 250px;
      height: 100%;
      position: fixed;
      background-color: #FFF;
      z-index: 1080;
      right: -250px;
      top: 0;
      transition: all 0.5s linear;
      overflow-y: auto;

      &::-webkit-scrollbar{
        width: 10px;
      }
      &::-webkit-scrollbar-thumb{
        background-color: #002069;
        border-left: 3px solid #FFF;
      }

      .container{
        padding: 0;
        display: flex;
        flex-direction: column;
      }

      &.shown{
        right: 0;
      }

      .style__topo__logo{
        max-width: 120px;
        text-align: center;
        margin: 10px auto 20px auto;
      }

      .main__menu{
        .form__area{
          position: absolute;
          top: 45px;
          left: 50%;
          transform: translateX(-50%);
          width: 200px;
          z-index: 300;
          padding: 20px;
          border-radius: 5px;
          background-color: #FFF;
          box-shadow: 0 0 15px 0 rgba(0,0,0,.3);
          -webkit-box-shadow: 0 0 15px 0 rgba(0,0,0,.3);
          -moz-box-shadow: 0 0 15px 0 rgba(0,0,0,.3);

          &:before{
            content: '';
            position: absolute;
            top: -10px;
            left: 50%;
            transform: translateX(-50%);
            border-left: 10px solid transparent;
            border-right: 10px solid transparent;
            border-bottom: 10px solid #FFF;
          }

          .style__form__area{
            text-align: center;
            margin: 0 auto;

            .form-group {
              margin-bottom: 10px;

              label{
                font-family: MontserratBold;
                font-size: 16px;
                line-height: 16px;
                color: #000000;
              }

              .campo__input {
                display: block;
                width: 100%;
                padding: 10px;
                background-color: #FFF;
                color: #666666;
                resize: none;
                font-family: MontserratRegular;
                font-size: 15px;
                border: 1px solid #6666;
                border-radius: 5px;
                text-align: center;

                &::placeholder {
                  color: #666666;
                  font-size: 13px;
                  font-family: MontserratItalic;
                }

                &:focus {
                  outline: none;
                }
              }
            }

            .form__link{
              font-family: MontserratItalic;
              font-size: 12px;
              line-height: 12px;
              color: #666666;
              margin-top: 10px;
            }

            .form__btn {
              margin-top: 20px;

              .btn__style {
                min-width: 120px;

                &:hover{
                  color: #FFF;
                  animation: tada 3s linear;
                }
              }
            }
          }
        }

        .menu__item{
          margin: 10px 0;
          position: relative;
          display: flex;
          padding: 10px 0 10px 20px;

          button{
            background-color: transparent;
            border: none;
          }

          .menu__link{
            display: flex;
            align-items: center;
            font-family: MontserratSemiBold;
            font-size: 14px;
            color: #002069;
            padding: 0;
            text-align: center;
            text-decoration: none;
            transition: all .3s linear;

            i{
              margin-right: 5px;
            }
          }

          &.menu__item--active{
            border-left: 5px solid #002069;

            .menu__link{
              &:before{
                opacity: 1;
              }
            }
          }
        }
      }
    }
  }

  .bg__menu{
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 1070;
    background-color: rgba(#002069, 0.6);
    animation: fadeIn 0.5s linear;

    &.hide{
      animation: fadeOut 0.5s linear;
    }
  }
}
