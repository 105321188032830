.contato{
  background-repeat: no-repeat;
  background-position: bottom center;
  background-size: auto;
  padding: 100px 0 700px 0;

  .header__titulo{
    text-align: center;
    margin: 0 auto 50px auto;

    @include media-breakpoint-up(lg){
      max-width: 820px;
    }

    .titulo{
      font-family: MontserratBold;
      font-size: 40px;
      line-height: 44px;
      color: #002069;
      margin-bottom: 20px;

      @include media-breakpoint-down(sm){
        font-size: 30px;
        line-height: 34px;
      }
    }

    .desc{
      font-family: MontserratRegular;
      font-size: 16px;
      color: #666666;
      line-height: calc(1em + 4px);
    }
  }

  .form__contato{
    .box__telefone{
      display: grid;
      grid-template-columns: repeat(5, 1fr);
      column-gap: 16px;

      .colspan {
        grid-column: span 4;
      }

      label{
        &:nth-child(2n){
          opacity: 0;
        }
      }
    }

    .form-group {
      margin-bottom: 20px;

      label{
        font-family: MontserratBold;
        font-size: 16px;
        line-height: 16px;
        color: #666666;
      }

      .campo__input {
        display: block;
        width: 100%;
        padding: 20px;
        background-color: #FFF;
        color: #666666;
        resize: none;
        font-family: MontserratRegular;
        font-size: 13px;
        border: 1px solid #dbdbdb;
        border-radius: 5px;

        &::placeholder {
          color: #666666;
          font-family: MontserratItalic;
        }

        &:focus {
          outline: none;
        }
      }
    }

    .custom__checkbox {
      position: relative;
      top: -10px;

      .rodape__check {
        position: fixed;
        left: -200vw;

        &:checked {
          ~ .rodape__check__label {
            &:after {
              opacity: 1;
            }
          }
        }
      }

      .rodape__check__label {
        position: relative;
        padding-left: 25px;
        margin: 0;
        font-family: MontserratRegular;
        font-size: 13px;
        line-height: 15px;
        color: #666666;

        &:before {
          content: '';
          position: absolute;
          width: 16px;
          height: 16px;
          border-radius: 50%;
          border: 2px solid #666666;
          background-color: transparent;
          left: 0;
          top: 50%;
          transform: translateY(-50%);
        }

        &:after {
          content: '';
          position: absolute;
          width: 9px;
          height: 9px;
          border-radius: 50%;
          background-color: #666666;
          left: 4px;
          top: 50%;
          transform: translateY(-50%);
          opacity: 0;
        }

        a{
          text-decoration: underline;
        }
      }
    }

    .v-select-output{
      width: 100%;
      padding: 20px;
      height: auto;
      color: #666666;
      font-family: MontserratItalic;
      font-size: 13px;
      background-color: #FFF;
      border: 1px solid #dbdbdb;
      border-radius: 5px;
      resize: none;

      &:focus{
        outline: none;
        box-shadow: none;
      }

      &:before{
        color: #666666;
        font-size: 12px;
      }
    }

    .v-option{
      background-color: transparent;
      font-family: MontserratRegular;
      font-size: 13px;
      color: #666666;

      &:hover{
        background-color: rgba(#002069 ,0.3);
        color: #FFF;
      }

      &.selected{
        background-color: rgba(#002069 ,0.8);
        font-family: MontserratBold;
        color: #FFF;
      }
    }

    .v-options {
      border: 0;
      border-radius: 5px;
      max-width: calc(100% - 60px);
      left: 30px !important;
      box-shadow: 0 0 10px 0 rgba(0,0,0,.4);
      z-index: 15;
    }

    .form__btn {
      margin-top: 10px;

      .btn__style {
        &:hover{
          color: #FFF;
          animation: tada 3s linear;
        }
      }
    }
  }

  .segura__mapa{
    margin-top: 26px;
    margin-bottom: 40px;
    border: 1px solid #dbdbdb;
    border-radius: 5px;

    .embed-responsive{
      border-radius: 5px;
      padding-bottom: 43%;

      @include media-breakpoint-down(md){
        padding-bottom: 50%;
      }

      @include media-breakpoint-down(xs){
        padding-bottom: 70%;
      }
    }
  }

  .box__creci{
    text-align: center;
    margin: 0 auto;
    border: 1px solid #dbdbdb;
    border-radius: 5px;
    background-color: #FFF;

    img{
      border-radius: 5px;
    }
  }

  .box__dados{
    margin-top: 20px;
    color: #666666;

    @include media-breakpoint-down(md){
      text-align: center;
      margin-left: auto;
      margin-right: auto;
    }

    .title__dados{
      font-family: MontserratBold;
      font-size: 16px;
      line-height: 18px;
    }

    .seg__dados{
      margin-top: 25px;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .link__contato{
        margin-bottom: 15px;
        font-size: 16px;
        line-height: 18px;
        font-family: MontserratRegular;

        & span{
          font-family: MontserratBold;
        }
      }
    }
  }
}