.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.message__validation {
  margin-top: 5px;
}

.has__success .form-control,
.has__success .v-file-label {
  border-color: #28a745;
}
.has__success .v-file-button {
  background: #28a745;
  color: #FFF;
}
.has__success .message__validation {
  color: #28a745;
}

.has__warning .form-control,
.has__warning .v-file-label {
  border-color: #ffc107;
}
.has__warning .v-file-button {
  background: #ffc107;
  color: #000;
}
.has__warning .message__validation {
  color: #ffc107;
}

.has__error .form-control,
.has__error .v-file-label {
  border-color: #dc3545;
}
.has__error .v-file-button {
  background: #dc3545;
  color: #FFF;
}
.has__error .message__validation {
  color: #dc3545;
}

.v-file {
  position: relative;
}

.v-file-input {
  position: relative;
  z-index: 2;
  width: 100%;
  height: calc(1.5em + 0.75rem + 2px);
  margin: 0;
  display: block;
  opacity: 0;
  cursor: pointer;
}

.v-file-label {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  border: 1px solid #ced4da;
  line-height: 1.5;
  font-size: 1rem;
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  border-radius: 0.25rem;
  left: 0;
  top: 0;
  cursor: pointer;
  background: #FFF;
}

.v-file-output {
  display: block;
  flex-grow: 1;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}

.v-file-button {
  display: inline-block;
  background: #DDD;
  font-size: 0.875rem;
  padding: 0 10px;
  border-radius: 0.25rem;
}

.label {
  display: inline-block;
  margin-bottom: 0.5rem;
}
.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.v-select-output {
  text-align: left;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}
.v-select-output:before {
  content: "";
  border-left: 0.5em solid transparent;
  border-right: 0.5em solid transparent;
  border-top: 0.5em solid currentColor;
  display: block;
  float: right;
  margin-left: 0.75rem;
  margin-top: 0.5rem;
}

.v-options {
  width: 100%;
  min-width: 180px;
  padding-top: 0;
  padding-bottom: 0;
  max-height: 220px;
  overflow: auto;
  max-height: 300px;
  overflow: auto;
  padding-left: 0;
  list-style: none;
  margin-bottom: 0;
}

.v-select-filter-area {
  padding-left: 10px;
  padding-right: 10px;
}

.v-select-filter {
  width: calc(100% - 20px);
  margin-left: auto;
  margin-right: auto;
  padding: 0.375rem 0.75rem;
  border: 1px solid #ccc;
  border-radius: 0.25rem;
  line-height: 1.5;
  font-size: 1rem;
  height: calc(2.25rem + 2px);
  margin-bottom: 15px;
  display: block;
  outline: none;
}
.v-select-filter:focus {
  border-color: #007bff;
  box-shadow: 0 0 5px rgba(0, 123, 255, 0.2);
}
.v-option {
  padding: 10px;
  color: #232323;
  cursor: pointer;
}
.v-option:not(.selected):hover {
  background: #eee;
}
.v-option.selected {
  background: var(--primary);
  color: var(--primary-inverse, #FFF);
}
.v-option.disabled {
  color: #999;
  pointer-events: none;
}
.line-clamp-2 {
  overflow: hidden;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 2;
}

.msg__cookies {
  background: #FFF;
  position: fixed;
  bottom: 15px;
  left: 50%;
  transform: translateX(-50%);
  padding: 15px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.4);
  width: 100%;
  z-index: 999999999;
  border: none;
  border-radius: 5px;
  font-family: MontserratRegular;
  font-size: 16px;
}
.msg__cookies a {
  color: #002069;
  font-weight: bold;
  text-decoration: underline;
}
.msg__cookies .btn__style:hover {
  animation: jello 3s linear;
}
@media (max-width: 767.98px) {
  .msg__cookies {
    max-width: calc(100% - 30px);
    font-size: 14px;
    text-align: center;
  }
  .msg__cookies a {
    display: inline-block;
  }
  .msg__cookies .content {
    margin-bottom: 10px;
  }
}
@media (min-width: 768px) {
  .msg__cookies {
    display: grid;
    grid-template-columns: 1fr min-content;
    grid-column-gap: 10px;
    align-items: center;
  }
  .msg__cookies .btn__style {
    white-space: nowrap;
  }
}
@media (min-width: 768px) and (max-width: 991.98px) {
  .msg__cookies {
    max-width: 720px;
  }
}
@media (min-width: 992px) and (max-width: 1199.98px) {
  .msg__cookies {
    max-width: 960px;
  }
}
@media (min-width: 1200px) {
  .msg__cookies {
    max-width: 1140px;
  }
}
